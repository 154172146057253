<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-10 mx-auto">
        <div class="card border-0 shadow-none">
          <div class="card-body p-5">
            <div class="row">
              <div class="col">
                <p class="mb-5">Swandoola Client Portal</p>
                <router-link to="/login" class="btn btn-primary float-right">
                  Login to Client Portal
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>